@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
}
.product-container {
  margin: 1.5rem 3rem 7rem 3rem;
  display: flex;
  gap: 2rem;
  flex-wrap: nowrap;
  .left-container {
    width: 60%;

    .linkContainer {
      display: flex;
      justify-content: space-between;
      .go-back {
        display: flex;
        gap: 0.25rem;
        line-height: 1rem;
        font-size: 0.8125rem;
        color: c.$primary;
        text-decoration: none;

        &:hover {
          color: c.$logo-light;
        }
      }
    }

    .slick-next {
      right: 1rem;
    }
    .slick-prev {
      left: 1rem;
    }
  }

  .right-container {
    width: 40%;
    border: 2px solid c.$grey-1;
    border-radius: 1rem;
    padding: 1.5rem 1rem;
    position: sticky;
    top: 8rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .title {
      @include t.title3;
      color: c.$primary;
    }
    .brand {
      color: c.$logo-light;
    }
    .pdp-description {
      white-space: break-spaces;
      text-align: left;
      text-wrap: wrap;
      color: c.$grey-6;
      @include t.title2;
    }

    .stock,
    .recommended {
      width: fit-content;
      margin: 0 auto;
      padding: 0.25rem 0.5rem;
      border-radius: 1.5rem;
      font-style: italic;
    }
    .stock {
      box-shadow: 0 0 5px c.$logo-red, 0 0 10px c.$logo-red,
        0 0 20px c.$logo-red;
    }
    .recommended {
      box-shadow: 0 0 5px c.$logo-light, 0 0 10px c.$logo-light,
        0 0 20px c.$logo-light;
    }
    .buttonLink {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      height: 3rem;
      background-color: c.$wpp;
      color: c.$white;
      border-radius: 1.5rem;
      padding-right: 1rem;
      width: fit-content;
      text-decoration: none;

      img {
        max-height: 110%;
        position: relative;
        left: -0.5rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-container {
    flex-direction: column;
    margin: 1rem 0.75rem 3rem 0.75rem;

    .left-container,
    .right-container {
      width: 100%;
    }
  }
}
