@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;

.mainDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  height: 22%;
  width: 100%;
}

.slick-slider {
  &.banner {
    .slick-track {
      height: 50vh;
    }
    .slick-slide {
      div {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .slick-dots {
      bottom: 0;
      li {
        button::before {
          font-size: 0.75rem;
          color: c.$primary;
          opacity: 0.5;
        }
        &.slick-active {
          button::before {
            color: c.$logo-light;
            opacity: 1;
          }
        }
      }
    }
  }

  &.recommended {
    .slick-dots {
      li {
        button::before {
          font-size: 0.75rem;
          color: c.$primary;
          opacity: 0.5;
        }
        &.slick-active {
          button::before {
            color: c.$logo-light;
            opacity: 1;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .slick-prev {
        left: -0.5rem;
      }
      .slick-next {
        right: -0.5rem;
      }
    }
  }

  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      z-index: 2;
      width: auto;
      height: auto;
      &::before {
        color: c.$secondary;
        font-size: 3rem;
        line-height: 3rem;

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }
    }
  }
  .slick-prev {
    left: -3rem;
  }
  .slick-next {
    right: -3rem;
  }
}
.listLinkContainer {
  margin: 5rem 0;
  text-align: center;
  .listLink {
    padding: 1.5rem;
    text-decoration: none;
    @include t.title4;
    color: c.$white;
    background-color: c.$third;
    border: 0.25rem solid c.$logo-red;
    border-radius: 10rem;
    transition: all 0.5s;

    &:hover {
      background-color: c.$logo-blueG;
      border-color: c.$third;
    }
  }
}
.slidersContainer {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.recommendedContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.brandsContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.brandBanner {
  height: 6rem;
  object-fit: contain;
  padding: 0 0.5rem;
}

@media screen and (max-width: 768px) {
  .slidersContainer {
    padding: 3rem 0.75rem;
    gap: 2rem;
  }
  .listLinkContainer {
    margin: 4rem 0 2rem;
    .listLink {
      font-size: 0.75rem;
      line-height: 1rem;
      padding: 0.5rem;
      border-width: 0.125rem;
    }
  }
  .slick-slider {
    &.banner {
      .slick-track {
        height: 30vh;
      }
    }
  }
}
