@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;
.mainLayout {
  padding: 0 2rem 3rem 2rem;
}
.listLayout {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.breadCrumb {
  padding: 0.5rem 1rem;
  background-color: c.$grey-3;
  color: c.$primary;
  display: flex;
  gap: 0.5rem;
  position: sticky;
  top: 5.5rem;
  z-index: 3;
}

.filterContainer {
  min-width: 4rem;
  width: 20%;
  padding: 0.5rem 1rem;
  background-color: c.$grey-3;
  height: fit-content;
  position: sticky;
  top: 9.5rem;
  .filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.filtersSection {
  padding: 0.25rem 0;
  .dropDown {
    background-color: c.$grey-3;
    @include t.title2;
    color: c.$primary;
    padding: 0.25rem;
    text-align: center;
    border-radius: 0.25rem 0.25rem 0 0;
    border: 1px solid c.$grey-5;
    height: fit-content;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      background-color: c.$grey-3;
    }
  }
  &.clear {
    .dropDown {
      background-color: c.$logo-red;
      border-color: c.$primary;
      color: c.$white;
      border-radius: 0.25rem;
      &:hover {
        border-width: 2px;
      }
    }
  }
  .dropDownOption {
    transition: all 0.5s ease-in-out;
    border: 1px solid c.$grey-5;
    border-radius: 0 0 0.25rem 0.25rem;
    text-align: center;
    cursor: pointer;
    &::-webkit-scrollbar {
      width: 0.5 rem;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: c.$primary;
      height: 0.5rem;
      border-radius: 0.25rem;
      width: 0.25rem;
    }
    &.dropDown_Hidden {
      max-height: 0;
      overflow: hidden;
    }
    &.dropDown_Visible {
      height: auto;
      max-height: 12rem;
      overflow-y: auto;
    }
    .option {
      margin: 0.25rem 0;
      display: block;
      text-decoration: none;
      color: c.$primary;
      cursor: pointer;
      &.selected {
        font-weight: 700;
        -webkit-text-stroke: 0.5px c.$logo-light;
      }
    }
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  width: calc(80% - 3rem);
  gap: 1rem;
  align-items: center;
}
.resultContainer {
  width: 100%;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
  gap: 0.75rem;

  &.empty {
    display: flex;
  }
  .mainProductCard {
    margin: 0;
    height: auto;
  }
}

.pagination {
  display: flex;
  gap: 0.25rem;
  .pageButton {
    min-width: 1.5rem;
    min-height: 1.5rem;
    padding: 0 0.25rem;
    background-color: c.$logo-light;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: c.$white;
    position: relative;
    cursor: pointer;
    &.active {
      background-color: c.$primary;
    }
    &.last {
      margin-left: 1.5rem;
      &::before {
        content: "...";
        position: absolute;
        left: -1.25rem;
        bottom: 0;
        color: black;
      }
    }
    &.first {
      margin-right: 1.5rem;
      &::before {
        content: "...";
        position: absolute;
        right: -1.25rem;
        bottom: 0;
        color: black;
      }
    }
  }

  .prevButton,
  .nextButton {
    min-width: 1.5rem;
    min-height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: c.$primary;
    font-size: 1.25rem;
    &.disabled {
      color: c.$grey-3;
    }
  }
  .prevButton {
    margin-right: 0.5rem;
  }
  .nextButton {
    margin-left: 0.5rem;
  }
}

.optionsContainer {
  width: 100%;
  background-color: c.$grey-3;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;

  .optionRight {
    display: flex;
    gap: 2rem;
    .optionDiv {
      display: flex;
      gap: 0.25rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .mainLayout {
    padding: 1rem 0.75rem 3rem 0.75rem;
    .breadCrumb {
      top: 4rem;
    }
  }
  .listLayout {
    flex-direction: column;
    gap: 1rem;
    .filterContainer {
      width: 100%;
      position: initial;
    }
    .rightContainer {
      width: 100%;
      .optionsContainer {
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
}
