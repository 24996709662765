@mixin fontInput {
  font-size: 1.5rem;
  line-height: 1.25rem;
  text-align: center;
}

@mixin buttonAdmin {
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: center;
}

@mixin button1 {
  font-size: 0.8125rem;
  line-height: 0.75rem;
  text-align: center;
}

@mixin title1 {
  font-size: 2rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin title2 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
}

@mixin title3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin title4 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin text1 {
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
}

@mixin text2 {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  text-align: left;
}
