@use "../theme/colors.scss" as c;

.menuTriggerContainer {
  position: relative;
}
.menuTrigger {
  display: flex;
  gap: 0.25rem;
}
.spinnerContainer {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.backdrop {
  display: none;
  position: absolute;
  top: calc(100% + 2rem);
  left: -2rem;
  width: 100vw;
  height: 100vh;
  &:has(.menuContainer.active) {
    display: initial;
    background: rgba(0, 0, 0, 0.2);
  }
}
.menuContainer {
  position: relative;
  left: 2rem;
  width: fit-content;
  display: none;
  color: c.$primary;
  &.active {
    display: flex;
    flex-direction: column;
    background-color: c.$grey-1;
    border-bottom-left-radius: 1rem;
    min-height: 12rem;
    justify-content: space-between;
  }
}

.categoryButton {
  padding: 0.5rem 1.5rem;
  transition: all 0.3s;
  color: c.$primary;
  text-decoration: none;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: c.$logo-light;
    color: c.$white;
  }
  &.all {
    border-bottom-left-radius: 1rem;
    text-decoration: none;
  }
}
.subcategoryButton {
  padding: 0.5rem 1.5rem;
  transition: all 0.3s;
  width: auto;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  color: c.$primary;
  &:hover {
    background-color: c.$logo-light;
    color: c.$white;
  }
}

.subMenuContainer {
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  overflow: hidden;
  color: c.$primary;
  transition: all 0.3s;
  min-height: 12rem;
  max-height: 50vh;
  &.active {
    width: max-content;
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    background-color: c.$grey-1;
    border-bottom-right-radius: 1rem;
    max-width: 60vw;
    overflow: auto;
    // gap: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .backdrop {
    left: -0.75rem;
    top: calc(100% + 0.8125rem);

    .menuContainer {
      left: 0;
      width: 100%;
      border-radius: 0;
    }

    .categoryButton {
      padding: 0.5rem 0 0 0;
      font-size: 0.875rem;

      &.all {
        border-radius: 0;
        text-align: center;
        background-color: c.$grey-3;
      }
    }

    .subMenuContainer {
      position: initial;
      max-height: 0;
      min-height: auto;
      width: 100%;
      border-radius: 0;
      margin-top: 0.25rem;

      .subcategoryButton {
        font-size: 0.75rem;
        color: c.$secondary;
      }
      &.active {
        max-height: none;
        border: 1px solid c.$primary;
        .subcategoryButton:not(:last-child) {
          border-bottom: 1px solid c.$primary;
        }
      }
    }
  }
}
