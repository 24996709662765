@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;
@use "../theme/buttons.scss" as b;
.popup-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: c.$backdrop;
  top: 0;
  left: 0;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background-color: c.$white;
  width: 70%;
  height: 50%;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: auto;

    .cancel,
    .delete {
      text-transform: uppercase;
      width: 10rem;
      height: 2rem;
    }

    .cancel {
      @include b.buttonPrimary;
    }
    .delete {
      @include b.buttonDelete;
    }
  }
}

.popup-title {
  @include t.title3;
}

.popup-text {
  @include t.title2;
  color: c.$logo-red;
}
