@use "../theme/colors.scss" as c;

.navBar {
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: c.$white;
  padding: 0.75rem 2rem;
  align-items: center;
  box-shadow: 0 0 2 px c.$black;
  position: sticky;
  top: 0;
  z-index: 4;

  .search-container {
    position: relative;
    width: 30%;
    margin-left: auto;
    .outsiderClick {
      position: absolute;
      left: 0;
      top: 0;
      width: 300vw;
      height: 300vh;
      transform: translate3d(-100vw, -100vh, 0);
      z-index: -1;
    }
    .search-bar {
      input {
        width: 100%;
        padding: 0.25rem;
      }
    }
    .search-results {
      position: absolute;
      right: 0;
      width: max-content;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      max-height: 50vh;
      overflow-y: auto;
      border: 1px solid c.$primary;

      &[hidden] {
        display: none;
      }

      .result {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid c.$logo-light;
        background-color: c.$grey-1;
        text-align: center;
        color: c.$primary;
        text-decoration: none;
        padding: 0.125rem 0;
        font-size: 0.8125rem;
        line-height: 1rem;
        cursor: pointer;
        &:hover {
          background-color: c.$logo-light;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navBar {
    padding: 0.5rem 0.75rem;

    .search-container {
      width: auto;
      .search-bar {
        input {
          padding: 0 0.125rem;
        }
      }
      .search-results {
        width: 100vw;
        top: 2.25rem;
        right: -0.75rem;
      }
    }
  }
}
