@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;

.loginDiv {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: c.$primary;

  .logo {
    height: 20%;
    @include c.logo-bg;
  }

  .loginForm {
    height: 50%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .inputForm {
      @include t.fontInput;
      height: 15%;
      width: 100%;
      max-width: 500px;
      max-height: 60px;
      border-radius: 5rem;
      margin-top: 5%;

      &:hover {
        border: 0.25rem solid c.$secondary;
      }
      &:focus-visible {
        border: 0.25rem solid c.$secondary;
        outline: none;
      }
    }

    .buttonForm {
      @include t.fontInput;
      background-color: c.$logo-blueG;
      border: none;
      border-bottom: 3px solid black;
      border-radius: 5rem;
      color: c.$white;
      height: 15%;
      width: 100%;
      transition: all 0.2s;
      max-width: 500px;
      max-height: 60px;
      margin-top: 10%;
    }

    .buttonForm:hover {
      cursor: pointer;
      background-color: c.$secondary;
    }

    .buttonForm:active {
      transform: translateY(1px);
    }
  }
}

.textDiv {
  width: 90%;
  background-color: rgba(228, 227, 227, 0.5);
}

@media (max-height: 550px) {
  .inputForm {
    height: 25%;
  }

  .buttonForm {
    height: 25%;
  }

  .loginForm {
    padding: 0px;
    width: 80%;
  }
}

@media (max-width: 550px) {
  .loginForm {
    justify-content: center;
  }
}
