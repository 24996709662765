@use "../theme/colors" as c;
@use "../theme/buttons" as b;

#admin {
  margin: 0 auto;
  min-height: 100vh;
  background-color: c.$primary;

  .admin-content {
    padding: 1.5rem;
    margin: 0 5rem;
    min-height: 70vh;
    gap: 1.5rem;
    background-color: c.$logo-blueG;
    border-top-right-radius: 3rem;
    display: flex;
  }
}

.scrollUp {
  @include b.buttonSecondary;
  padding: 0.5rem;
}
