@use "../theme/colors.scss" as c;

.topBarText {
  padding: 0.5rem 0;
  background-color: c.$secondary;
  color: c.$white;
}

@media screen and (max-width: 768px) {
  .topBarText {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
