@use "../theme/colors.scss" as c;

#headerAdmin {
  margin: 0 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;

    .logo {
      height: 6rem;
      @include c.logo-bg;
    }
    .name {
      height: 6rem;
      @include c.logo-bg;
    }
  }

  .menu-container {
    width: max-content;
    display: flex;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: c.$grey-1;

    .menu-link {
      padding: 1rem 1rem 0.5rem 1rem;
      text-transform: uppercase;
      display: block;
      text-decoration: none;
      color: c.$black;

      &.first {
        border-top-left-radius: 1rem;
      }
      &.last {
        border-top-right-radius: 1rem;
      }
      &.active {
        background-color: c.$logo-blueG;
        color: c.$white;
        transition: all 0.3s;
      }
    }
  }
}
