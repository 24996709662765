@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;
@use "../theme/buttons.scss" as b;

.aboutBanner {
  max-height: 30rem;
}

#ubicacion {
  .locationContainer {
    display: flex;
    gap: 1rem;
  }
}

.aboutInfo {
  padding: 2rem 3rem 5rem;

  #whoWeAre {
    margin: 4rem 0 1.5rem;
    display: flex;
    gap: 1rem;
    article {
      width: 25%;
      border: 1px solid c.$logo-light;
      padding: 2.25rem 0.75rem 0.75rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .iconAboutContainer {
        padding: 0.25rem;
        background-color: c.$white;
        border: 1px solid c.$logo-light;
        border-radius: 0.5rem;
        position: absolute;
        top: -3.5rem;
        .iconAbout {
          width: 4rem;
          height: 4rem;

          &.calendar {
            width: 3rem;
            height: 3rem;
            margin: 0.5rem;
          }
        }
        &:hover {
          .iconAbout {
            transform: rotate(360deg);
            transition: transform 1.5s;
          }
        }
      }
      p {
        font-size: 0.8125rem;
        line-height: 1rem;
        color: c.$grey-9;
      }
    }
  }

  div {
    color: c.$primary;
  }

  #location {
    h2 {
      margin-bottom: 0.75rem;
    }

    .locationContainer {
      display: flex;
      gap: 1rem;

      iframe {
        border-radius: 1rem;
      }
      .data {
        border: 1px solid c.$logo-light;
        padding: 1.5rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          @include t.title2;
          margin-bottom: 1.25rem;
        }
        p {
          text-align: left;
          color: c.$grey-6;
          text-wrap: nowrap;
        }
      }
    }
    .buttonLink {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      height: 3rem;
      background-color: c.$wpp;
      color: c.$white;
      border-radius: 1.5rem;
      padding-right: 1rem;
      width: fit-content;
      text-decoration: none;
      margin: 0 auto;

      img {
        max-height: 110%;
        position: relative;
        left: -0.5rem;
      }
    }
  }

  #gallery {
    margin-top: 2rem;
    .galleryTitle {
      display: flex;
      gap: 2rem;
      align-items: center;
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        @include b.buttonPrimary;
        padding: 0.25rem;
      }
    }
    .imgContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      img {
        max-width: calc(50% - 0.25rem);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .aboutInfo {
    padding: 1.25rem 0.75rem 3rem 0.75rem;
    #whoWeAre {
      flex-direction: column;
      gap: 4rem;
      article {
        width: 100%;
      }
    }
    #location {
      .locationContainer {
        flex-direction: column-reverse;
        gap: 0;
        .data {
          border-radius: 1rem 1rem 0 0;
          border-bottom: none;
          gap: 0.75rem;
        }
        iframe {
          border-radius: 0 0 1rem 1rem;
        }
      }
    }
  }
}
