#headerMain {
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .headerLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    cursor: pointer;

    .logo,
    .name {
      height: 3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  #headerMain {
    margin-left: 0.5rem;
    .headerLogo {
      .logo,
      .name {
        height: 1.5rem;
      }
    }
  }
}
