@use "../theme/colors.scss" as c;

.footerContainer {
  background-color: c.$primary;
  padding: 2rem 0;
  display: flex;
  justify-content: space-around;
  color: c.$white;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 20%;
    p {
      text-align: left;
      font-size: 1rem;
      line-height: 1rem;
    }
    a {
      display: flex;
      align-items: center;
      white-space: pre;
      text-decoration: none;
      color: c.$white;

      &:hover {
        color: c.$logo-light;
        transition: all 0.5s;
      }
    }
    h3 {
      font-size: 1.125rem;
      line-height: 1.5rem;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid white;
    }
  }
}

@media screen and (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    padding: 0.75rem;
    gap: 1rem;
    div {
      width: 100%;
    }
  }
}
