@use "./colors.scss" as c;
@use "./typography" as t;

@mixin buttonPrimary {
  @include t.button1;
  border: 2px solid c.$third;
  background-color: c.$white;
  color: c.$third;
  border-radius: 0.5rem;

  &:hover {
    background-color: c.$third;
    color: c.$white;
    cursor: pointer;
  }

  &:active {
    transform: translate3d(0.1rem, 0.1rem, 0);
  }

  &:disabled {
    border-color: c.$grey-2;
    background-color: c.$grey-1;
    color: c.$white;
    &:hover {
      border-color: c.$grey-2;
      color: c.$white;
    }
    &:active {
      transform: none;
    }
  }
}

@mixin buttonSecondary {
  @include t.button1;
  border: 2px solid c.$white;
  background-color: c.$third;
  color: c.$white;
  border-radius: 0.5rem;

  &:hover {
    background-color: c.$white;
    color: c.$third;
    cursor: pointer;
  }

  &:active {
    transform: translate3d(0.1rem, 0.1rem, 0);
  }

  &:disabled {
    border-color: c.$grey-2;
    background-color: c.$grey-1;
    color: c.$third;
    &:hover {
      border-color: c.$grey-2;
      color: c.$third;
    }
    &:active {
      transform: none;
    }
  }
}

@mixin buttonRecover {
  @include t.button1;
  border: 2px solid c.$success;
  background-color: c.$white;
  color: c.$success;
  border-radius: 0.5rem;

  &:hover {
    background-color: c.$success;
    color: c.$black;
    cursor: pointer;
  }

  &:active {
    transform: translate3d(0.1rem, 0.1rem, 0);
  }

  &:disabled {
    border-color: c.$grey-2;
    background-color: c.$grey-1;
    color: c.$white;
    &:hover {
      border-color: c.$grey-2;
      color: c.$white;
    }
    &:active {
      transform: none;
    }
  }
}

@mixin buttonDelete {
  @include t.button1;
  border: 2px solid c.$logo-red;
  background-color: c.$white;
  color: c.$logo-red;
  border-radius: 0.5rem;

  &:hover {
    background-color: c.$logo-red;
    color: c.$black;
    cursor: pointer;
  }

  &:active {
    transform: translate3d(0.1rem, 0.1rem, 0);
  }

  &:disabled {
    border-color: c.$grey-2;
    background-color: c.$grey-1;
    color: c.$white;
    &:hover {
      border-color: c.$grey-2;
      color: c.$white;
    }
    &:active {
      transform: none;
    }
  }
}
