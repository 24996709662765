@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;
@use "../theme/inputs.scss" as i;
@use "../theme/buttons.scss" as b;

.menu-options {
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: c.$grey-1;
  gap: 1rem;
  min-width: 20%;
  border-radius: 1rem;

  .button-option {
    @include t.buttonAdmin;
    height: 10%;
    background: none;
    border: none;
    border-bottom: 1px solid c.$black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &.active {
      &::after {
        position: absolute;
        right: -0.6rem;
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy43OTI5IDEwLjI5MzZDMTQuMTgzNCA5LjkwMzEgMTQuODE2NiA5LjkwMzEgMTUuMjA3MSAxMC4yOTM2TDIyLjkxNDIgMTguMDAwN0wxNS4yMDcxIDI1LjcwNzhDMTQuODE2NiAyNi4wOTg0IDE0LjE4MzQgMjYuMDk4NCAxMy43OTI5IDI1LjcwNzhDMTMuNDAyNCAyNS4zMTczIDEzLjQwMjQgMjQuNjg0MSAxMy43OTI5IDI0LjI5MzZMMjAuMDg1OCAxOC4wMDA3TDEzLjc5MjkgMTEuNzA3OEMxMy40MDI0IDExLjMxNzMgMTMuNDAyNCAxMC42ODQxIDEzLjc5MjkgMTAuMjkzNloiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=");
      }
    }
    &:hover {
      cursor: pointer;
      background-color: rgba(29, 192, 244, 0.1);
    }
  }
}

.option-container {
  padding: 2rem 1rem;
  background-color: c.$grey-1;
  border-radius: 1rem;
  width: calc(80% - 1.5rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .search-container {
    position: relative;
    .search-name {
      @include t.title2;
      margin-bottom: 1rem;
    }
    .search-bar {
      input {
        @include i.small;
        width: 100%;
        padding-left: 1rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .search-results {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: c.$white;
      z-index: 999;
      border: 1px solid c.$black;
      border-top: 0;
      border-bottom: 0;

      .result {
        padding: 0.25rem 0;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid c.$black;

        &:hover {
          background-color: c.$logo-blueG;
          cursor: pointer;
          color: c.$white;
        }
      }
    }
  }

  &#all-container {
    .search-container {
      width: 20rem;
      .search-bar {
        input {
          @include i.small;
          width: 100%;
          padding-left: 1rem;
          border-radius: 0.15rem;
          height: 1.25rem;
        }
      }
    }
  }

  .spinner-row {
    display: flex;
    gap: 1rem;
  }

  .option-title {
    @include t.title1;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .filter-title {
      @include t.title2;
    }
    .filter-navbar {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
      flex-wrap: wrap;
      .filter-option {
        display: flex;
        flex-direction: column;
      }
      .select-name {
        @include t.text1;
        margin-right: 0.5rem;
      }
    }
  }

  .selector {
    width: fit-content;
    max-width: 12rem;
  }
  .errors-container,
  .success-container,
  .alert-container {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    border-radius: 0.5rem;
    font-weight: bolder;
    font-size: 0.8125rem;
    width: fit-content;
  }

  .errors-container {
    background-color: c.$logo-pink;
    border: 1px solid c.$logo-red;
  }

  .success-container {
    border: 1px solid c.$success;
    color: c.$success;
  }

  .alert-container {
    background-color: c.$alert;
    color: c.$white;
  }

  .selected-container {
    display: flex;
    gap: 1rem;
    .change-button {
      @include b.buttonPrimary;
    }
  }
  .create-form {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    .select-container-small {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: calc(50% - 0.5rem);
      &.description {
        width: 100%;
      }
      .input-small {
        padding-left: 0.5rem;
      }
      .input-small,
      .select-small {
        @include i.small;
        width: 100%;

        &.description {
          height: 8rem;
        }
      }
    }
    .select2-container {
      display: flex;
      gap: 1rem;
    }
    .input-container-small,
    .select-container-large {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: calc(50% - 1rem);
      &.description {
        width: 100%;
      }
      .input-small {
        padding-left: 0.5rem;
      }
      .input-small,
      .select-small {
        @include i.small;
        width: 100%;

        &.description {
          height: 8rem;
        }
      }
      .img-create-placeholder {
        width: 100%;
        aspect-ratio: 1;
        border: 1px solid c.$black;
        border-radius: 1rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
      }
      .image-input-container {
        display: flex;
        gap: 0.5rem;
        width: 100%;
      }
    }
    .imgCardContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      .imgCard {
        width: 4rem;
        height: 4rem;
        position: relative;
        background-color: white;

        .imgItem {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .imgButton {
          @include b.buttonPrimary;
          position: absolute;
          top: 0;
          right: 0;
          height: 1rem;
          width: 1rem;
          padding: 0;
          border-radius: 100%;
          border-width: 1px;
        }
      }
    }
    .save-button-container {
      display: flex;
      gap: 1rem;
    }
    .save-button {
      @include b.buttonPrimary;
      width: 8rem;
      padding: 0.5rem;
    }
  }

  &.create-subcategory,
  &.create-category {
    .create-form {
      flex-direction: column;
    }
  }

  .button-delete {
    @include b.buttonDelete;
    width: 8rem;
    padding: 0.5rem;
    height: 50%;
    margin: auto 0;
  }

  .button-recover {
    @include b.buttonRecover;
    width: 8rem;
    padding: 0.5rem;
    height: 50%;
    margin: auto 0;
  }

  &.create-product,
  &.edit-product {
    .create-form {
      .input-container-small {
        justify-content: space-between;
      }
      .select-container-large {
        width: 100%;
      }
    }
  }

  &:has(.search-container) {
    &.edit-product,
    &.edit-brand,
    &.edit-category,
    &.edit-subcategory {
      gap: 0.25rem;
    }
  }

  &.delete {
    position: relative;
  }
}
