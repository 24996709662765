@use "../theme/colors.scss" as c;
@use "../theme/typography.scss" as t;
@use "../theme/buttons.scss" as b;

.mainProductCard {
  height: 100%;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border: 0.5px solid c.$primary;
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-content: center;
  justify-content: space-between;
  &.recommended {
    border: 2px solid c.$logo-light;
  }
  &.unavailable {
    .productButtons {
      .pdpLink {
        z-index: 3;
      }
    }
  }
  .nameSection {
    display: flex;
    flex-direction: column;
  }
  .cardTitle {
    @include t.title2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
  .cardImg {
    aspect-ratio: 1;
  }
  .cardDescriptionWrapper {
    width: fit-content;
    @include b.buttonSecondary;
    padding: 0.5rem;
  }
  .cardDescription {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    background-color: rgba(30, 194, 243, 0.5);
    backdrop-filter: saturate(180%) blur(5px);
    border-radius: 1rem;
    white-space: pre-line;
    padding: 1rem 1rem 0.5rem 1rem;
    color: c.$primary;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.active {
      opacity: 1;
      z-index: 2;
    }
    .cardDescriptionWrapper {
      margin: 0 auto;
    }
  }
  .unavailableBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .unavailableText {
      background-color: c.$primary;
      font-size: 1.125rem;
      line-height: 1.25rem;
      padding: 0.25rem;
      border-radius: 0.25rem;
      color: c.$white;
    }
  }
  .productImages {
    position: relative;
    overflow: hidden;
    .recommendedTag {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem 0 0.5rem 0;
      background-color: c.$logo-light;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 600;
    }
  }
  .productButtons {
    display: flex;
    justify-content: space-around;
    .pdpLink {
      @include b.buttonSecondary;
      text-decoration: none;
      padding: 0.5rem;
    }
  }
  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      &::before {
        color: c.$primary;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-dots {
    bottom: 0.5rem;
    li {
      height: auto;
      width: auto;
      button {
        height: auto;
        width: auto;
        &::before {
          font-size: 0.25rem !important;
          color: white !important;
          line-height: 0.25rem;
          width: auto;
          height: auto;
          border: 0.5px solid black;
          border-radius: 50%;
        }
      }
      &.slick-active {
        button::before {
          color: c.$logo-light !important;
        }
      }
    }
  }
}
