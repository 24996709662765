@use "./colors.scss" as c;
@mixin small {
  height: 2rem;
  border: 1px solid c.$primary;
  border-radius: 0.5rem;
  &:hover,
  &:active,
  &:focus-visible {
    border-width: 1.5px;
  }
  &:focus-visible {
    outline: none;
  }
}
