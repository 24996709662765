@use "../theme/colors.scss" as c;
@use "../theme/buttons.scss" as b;
@use "../theme/typography.scss" as t;

.admin-card {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 1rem;
  border: 1px solid c.$primary;
  background-color: c.$white;
  gap: 0.5rem;
  .basicData {
    display: flex;
    justify-content: space-between;
  }
  .moreData {
    transition: all 0.7s;
    &.hidden {
      max-height: 0;
      overflow: hidden;
    }
    &.active {
      padding: 1rem 1rem 1rem 0;
      max-height: initial;
    }
    .data {
      display: flex;
      gap: 1rem;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
    gap: 0.5rem;

    .title {
      color: c.$primary;
      @include t.title2;
    }

    .text {
      color: c.$logo-blueG;
      @include t.text1;
    }

    &.product-name {
      width: 10rem;
    }

    &.max-text {
      width: 35%;
    }
  }

  .viewMore {
    @include b.buttonPrimary;
    height: 2rem;
    width: 6rem;
    margin: auto 0;
  }
  .productImage {
    height: 4rem;
    width: 4rem;
    object-fit: contain;
  }

  &.category,
  &.subcategory,
  &.brand {
    .basicData {
      justify-content: initial;
    }
    .description {
      padding-left: 0;
      width: 40%;
    }
    .viewMore {
      margin-left: auto;
    }
    .moreData {
      .data {
        gap: 0;
      }
    }
  }

  &.delete {
    .button-delete {
      height: fit-content;
      margin: auto 0;
    }
    .buttons-container {
      height: auto;
      margin: auto 0;
      display: flex;
      gap: 1rem;
      .button-recover,
      .button-delete {
        height: auto;
        margin: 0;
      }
    }
  }
}
