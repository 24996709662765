$primary: #0d1837;
$secondary: #047db0;
$third: #233b80;

$success: #6fbe22;
$alert: #f19e38;
$wpp: #23a216;

$logo-red: #eb1b22;
$logo-light: #1ec2f3;
$logo-pink: #ddabae;
$logo-blueG: #2f6b8c;
$logo-break: #d86466;

$white: #fff;
$black: #000;

$grey-1: #f5f5f5;
$grey-2: #f0f0f0;
$grey-3: #e1e1e1;
$grey-4: #c8c8c8;
$grey-5: #afafaf;
$grey-6: #8c8c8c;
$grey-7: #6e6e6e;
$grey-8: #505050;
$grey-9: #282828;

$backdrop: rgba(0, 0, 0, 0.6);

@mixin logo-bg {
  background: rgb(13, 24, 55);
  background: linear-gradient(
    0deg,
    rgba(13, 24, 55, 1) 0%,
    rgba(13, 24, 55, 1) 18%,
    rgba(255, 255, 255, 1) 18%,
    rgba(255, 255, 255, 1) 82%,
    rgba(13, 24, 55, 1) 8%,
    rgba(13, 24, 55, 1) 100%
  );
}
